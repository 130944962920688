import Navigation from './modules/Navigation'
import Gallery from './modules/Gallery'
import MediaGroupTabbedAccordion from './modules/MediaGroupTabbedAccordion'
import JumpLink from './modules/JumpLink'
import ListSliderFilter from './modules/ListSliderFilter'
import MediaGroupIntersectionObserver from './modules/MediaGroupIntersectionObserver'
import GridFilter from './modules/GridFilter'
import ContentPostsFilter from './modules/ContentPostsFilter'
import FadeUp from './modules/FadeUp'
import MapListToggle from './modules/MapListToggle'
import BookingModal from './modules/BookingModal'
import EventCalender from './modules/EventsCalender'

new BookingModal()
new Navigation()
new MediaGroupTabbedAccordion()
new JumpLink()
new EventCalender()


document.querySelectorAll('.js-map-list-toggle').forEach(element => {
    new MapListToggle(element)
})

document.querySelectorAll('.c-media__heading, .c-button-group__heading, .c-list__heading, .c-media-group-tabbed-accordion__subheading, .c-form__heading, .c-cta__heading').forEach(element => {
    new FadeUp(element)
})

document.querySelectorAll('.c-content-posts__filter').forEach(element => {
    new ContentPostsFilter(element)
})

document.querySelectorAll('.js-list-slider-filter').forEach(element => {
    new ListSliderFilter(element)
})

document.querySelectorAll('.js-grid-filter').forEach(element => {
    new GridFilter(element)
})

document.querySelectorAll('.c-grid-filter--media').forEach(element => {
    new Gallery(element)
})

$(() => {

    $('.shop-links .header-shop-dropdown').click(function(e) {
        e.preventDefault();

        $(this).parent().find('.hidden-shop-links').toggleClass('showing');
    });

    $('.c-media-group--history').each( function() {
        new MediaGroupIntersectionObserver(this);
    })

    window.addEventListener('resize', () => {
        if (!$('.c-list--signature.c-list--slider .c-slider--list').length > 0) return;

        $('.c-list--signature.c-list--slider .c-slider--list').each(() => {
            const width = $(window).width();

            // $('.c-list--signature.c-list--slider .c-slider--list').flickity('destroy');

            if (width < 1024) {
                $('.c-list--signature.c-list--slider .c-slider--list').flickity({
                    cellAlign: 'left',
                    contain: true,
                    pageDots: false,
                    wrapAround: false,
                    prevNextButtons: false
                });
            } else {
                $('.c-list--signature.c-list--slider .c-slider--list').flickity({
                    cellAlign: 'center',
                    contain: true,
                    pageDots: true,
                    wrapAround: true,
                    prevNextButtons: true
                });
            }
        });
    });

    window.dispatchEvent(new Event('resize'));

    $('.c-content-posts').each((key, element) => {
        let target = $(element),
            filter = target.find('.c-content-posts__filter')

        if (filter.length > 0) return

        let headings = target.find('.c-content-posts__headings'),
            text = target.find('.c-content-posts__text'),
            buttons = target.find('.c-content-posts__buttons')

        headings.add(text).add(buttons).wrapAll('<div class="c-content-posts__content"></div>')
    })

    
})
