export default class Navigation {
    constructor() {
        this.component = $('.c-page-header')
        this.toggleID = null
        this.navIsOpen = false
        this.lastScrollTop = 0

        window.Navigation = this

        this.init()
    }

    init() {
        if(this.component.length == 0) return

        this.getElements()
        this.accordionHandler()
        this.inputHandler()
        // this.scrollHandler()
        this.setScrollOffset()
    }

    getElements() {
        this.$ = {}
        this.$.body = $(document.body)
        this.$.menuLinks = this.component.find('.menu__link')
        this.$.toggleInputs = $('.c-menu__input')
        this.$.toggleSections = this.component.find('.c-page-header__menu')
        this.$.toggleLabels = this.component.find('[for="menu-toggle--primary"]')
    }

    scrollHandler() {
        window.addEventListener('scroll', ()=> {
            this.updateScrollClass()
        }, false)


    }

    updateScrollClass() {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop

        if (scrollTop > this.lastScrollTop) {
            $('body').addClass('page-is-scrolling-down')
        } else {
            $('body').removeClass('page-is-scrolling-down')
        }

        this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    }

    accordionHandler() {
        this.$.menuLinks.each((i, element) => {
            if(element.dataset['navigation'] == undefined) return

            $(element).on('click', e => {

                if(window.innerWidth < 1280) e.preventDefault()

                $(element).closest('.menu__item').toggleClass('is-open')

                // render images
                $('body').trigger('revealOffcanvas')
            })
        })
    }

    inputHandler() {
        this.$.toggleInputs.on('change', (e) => {
            let target = e.target
            let variation = e.target.id.replace('menu-toggle--', '')
            let section = this.component.find('.c-page-header__menu--' + variation)
            let label = this.component.find('[for=menu-toggle--' + variation + ']')

            $('.' + target.className).not(target).prop('checked', false)
            this.$.toggleSections.not('.c-page-header__menu--' + variation).removeClass('is-open')
            this.$.toggleLabels.not('[for=menu-toggle--' + variation+ ']').removeClass('is-open')

            // toggle class to body
            target.checked ? this.$.body.addClass('nav-is-open') : this.$.body.removeClass('nav-is-open')

            // toggle class to target
            target.checked ? section.addClass('is-open') : section.removeClass('is-open')

            // toggle class to label
            target.checked ? label.addClass('is-open') : label.removeClass('is-open')

            // render images
            $('body').trigger('revealOffcanvas')
        })
    }

    setScrollOffset() {
        let headerOffset = $('.c-page-header--top').height()
        // get the margin value of the first c-row
        let marginOffset = parseInt(getComputedStyle(document.querySelector('.c-row')).marginBottom)
        // get combined height of page header children
        this.component.data('scroll-offset', headerOffset + marginOffset);
    }
}