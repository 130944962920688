import { _$ } from "./shared"

class FadeUp {
    constructor(element) {
        element.classList.add('a-fade-up')
        this.observeText(element)
    }

    observeText(element) {
        let callback = (entries, observer) => {
            entries.forEach((entry) => {
                element.classList.toggle('is-intersecting', entry.isIntersecting || element.getBoundingClientRect().bottom < 0)
            });
        }

        let observer = new IntersectionObserver(callback);

        observer.observe(element)
    }
}

export default FadeUp