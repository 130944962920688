export default class BookingModal {
    constructor() {
        this.id = 'offcanvas-book'
        this.prefix = 'c-offcanvas'
        this.hash = '#book'
        this.$component = $('.c-offcanvas--book')
        this.locationKey = null
        this.data = null
        this.locationData = null
        this.breadcrumbsArr = ['Book'];

        this.init()
    }

    init() 
    {
        if(this.$component.length == 0) return

        this.setElements()
        this.setData()
        this.setLocationKey()

        // if url has hash
        if(window.location.hash == this.hash) this.open()

        this.eventListeners()
    }

    setElements() 
    {
        this.$ = {}

        this.$.body = $(document.body)
        this.$.header = $('.c-page-header')
        this.$.breadcrumbs = $('.c-offcanvas__breadcrumbs')
        this.$.closeBtn = this.$component.find('.c-offcanvas__close')
        this.$.pageContainer = this.$component.find('.c-offcanvas__pages')
    }

    setLocationKey()
    {
        if(this.$.header.length > 0 && 'location' in this.$.header[0].dataset) {
            this.locationKey = this.$.header[0].dataset.location

            if('options' in this.data) {
                this.data['options'].forEach(d => {
                    if(this.locationKey == d.icon) return this.locationData = d
                })
            }
        }
    }

    setData()
    {
        this.data = JSON.parse(this.$component[0].dataset.moduleBooking)
    }

    getDataByLocationID(id)
    {
        let data = null

        this.data.forEach(item => {
            if(item['id'] == id) return data = item
        })

        return data
    }

    eventListeners()
    {
        // toggle on popstate of hash
        window.addEventListener('popstate', (event) => {
            if (event.target.location.hash !== this.hash) return this.close()

            this.open()
        })

        // on click closer
        this.$.closeBtn.on('click', e => {
            this.close()
        })

        // on click breadcrumbs
        // if in future deeper levels are needed this needs work
        this.$.breadcrumbs.on('click', 'li', e => {
            if(e.target.dataset.index == 1 && $(e.target).next().length) {
                this.resetToTopLevel()
            }
        })
    }

    close()
    {
        history.replaceState(null, null, ' ')
        this.$.body.removeClass(this.id + '-is-active')

        this.$.pageContainer.empty()
        this.breadcrumbsArr = ['Book'];
    }

    open() 
    {
        if(this.locationData) {
            this.breadcrumbsArr = ['Book', this.data.option_namespace];
            this.renderPage(this.locationData)
        } else {
            this.renderPage(this.data)
        }

        this.$.body.addClass(this.id + '-is-active')
    }

    renderPage(data)
    {
        if(!this.data) return

        this.$.pageContainer.empty()
        // create page section
        let section = document.createElement('section')
        section.classList.add(this.prefix + '__page')
        section.dataset.namespace = data['option_namespace']

        // create title
        if(data['option_title'] !== '') {
            let heading = document.createElement('h4')
            heading.classList.add(this.prefix + '__heading')
            heading.innerText = data['option_title']
            section.append(heading)
        }

        // create heading
        if(data['option_heading'] !== '') {
            let heading = document.createElement('h4')
            heading.classList.add(this.prefix + '__heading')
            heading.innerText = data['option_heading']
            section.append(heading)
        }
        
        if('options' in data && data['options'].length > 0) {
            // create option container
            let optionContainer = document.createElement('div')
            optionContainer.classList.add(this.prefix + '__options')
            section.append(optionContainer)

            // push into index arr for use with breadcrumbs
            this.breadcrumbsArr.push(data.option_namespace)

            // create options
            data['options'].forEach(option => {
                let element = document.createElement('button')

                if(option.url) {
                    // get url ready
                    element = document.createElement('a')
                    element.href = option.url.url
                    if(option.url.target !== '') element.setAttribute('target', option.url.target)
                    if(option.url.title !== '') element.innerText = option.url.title
                } else {

                    // add data and handler function
                    element.data = option
                    element.onclick = (e)=> this.optionHandler(e)
                }   

                element.classList.add(this.prefix + '__option')
                
                if(element.innerText == '') element.innerText = option.name   
                
                // add icon 
                if('icon' in option && option['icon'] !== '') {
                    let icon = document.createElement('i')
                    icon.classList.add('c-icon', 'c-icon--' + option['icon'])

                    element.prepend(icon)
                }
                
                optionContainer.append(element)
            })
        }

        // create logo
        if(data['option_image']) {
            let imageContainer = document.createElement('div')
            let image = document.createElement('img')

            imageContainer.classList.add(this.prefix + '__image')
            image.src = data['option_image']
            
            imageContainer.append(image)
            section.append(imageContainer)
        }

        this.$.pageContainer.append(section)
        this.renderBreadcrumbs()
    }

    renderBreadcrumbs() {

        this.$.breadcrumbs.empty()

        let list = document.createElement('ul')
        list.dataset.length = this.breadcrumbsArr.length
        
        this.breadcrumbsArr.forEach((name, i) => {
            let item = document.createElement('li')
            item.dataset.index = i
            item.innerText = name
            list.append(item) 
        })

        this.$.breadcrumbs.append(list) 
    }

    optionHandler(e)
    {
        this.renderPage(e.target.data)
        this.renderBreadcrumbs(e.target.data)
    }

    resetToTopLevel() {
        this.breadcrumbsArr = ['Book'];
        this.renderPage(this.data)
    }
}