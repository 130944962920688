class ListSliderFilter {
    constructor(container) {
        this.slider = container.querySelector('.js-flickity')
        this.slides = this.slider.querySelectorAll('.js-flickity-cell')
        this.filter = container.querySelector('.js-list-select-filter')
        this.category = container.querySelector('.js-list-select-category')

        this.filter.addEventListener('change', this.updateSlider.bind(this))
        this.category.addEventListener('change', this.updateSlider.bind(this))
    }

    updateSlider() {
        const currentFilter = this.filter.value
        const currentCategory = this.category.value
        const sliderInstance = new Flickity(this.slider)

        if (Flickity.data(this.slider).isActive) {
            sliderInstance.remove(this.slides)

            this.slides.forEach(slide => {
                const matchesFilter = currentFilter === '*' || slide.dataset.filter === currentFilter
                const matchesCategory = currentCategory === '*' || slide.dataset.category === currentCategory

                if (matchesFilter && matchesCategory) {
                    sliderInstance.append(slide)
                }
            })

            sliderInstance.select(0)
        } else {
            this.slides.forEach(slide => {
                const matchesFilter = currentFilter === '*' || slide.dataset.filter === currentFilter
                const matchesCategory = currentCategory === '*' || slide.dataset.category === currentCategory

                if (matchesFilter && matchesCategory) {
                    slide.classList.remove('is-inactive')
                } else {
                    slide.classList.add('is-inactive')
                }
            })
        }
    }
}

export default ListSliderFilter
