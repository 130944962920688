import { _$, _$$ } from "./shared"

class JumpLink {
    constructor() {
        window.addEventListener('load', function() {
            let hash = document.location.hash.split('#')[1]
            let selector = _$('.' + hash)

            if(selector) {
                setTimeout(function() {
                    selector.scrollIntoView(true)
                }, 10);
            }
        })

        document.querySelectorAll('[data-target]').forEach(element => {
            element.addEventListener('click', event => {
                let target = document.querySelector(`[data-id="${event.target.dataset.target}"]`)

                if (target) {
                    target.scrollIntoView()
                }
            })
        })
    }
}

export default JumpLink