class MapListToggle {
    constructor(container) {
        this.container = container

        this.initialState()
        this.eventListeners()
    }
    initialState() {
        let children = this.container.querySelectorAll('.c-interactive-map, .c-media-group--hotel-list')
        this.toggleState(children[children.length - 1].classList.contains('c-interactive-map'))
    }

    eventListeners() {
        this.container.querySelectorAll('.js-toggle-list, .js-toggle-map').forEach((button) => {
            button.addEventListener('click', () => {
                this.toggleState(button.classList.contains('js-toggle-list'))
            })
        })
    }

    toggleState(isList) {
        this.container.classList.toggle('is-showing-list', isList)
        this.container.classList.toggle('is-showing-map', !isList)
        $('body').trigger('revealOffcanvas');
    }

}

export default MapListToggle
