export default class EventCalender {
    constructor() {
        this.$component = $('.c-events-calendar')

        this.init()
    }

    init() {
        this.$component.each((key, element) => {
            this.wrapFilters(element)
        })
    }

    wrapFilters(component) {
        let 
            $component = $(component),
            $list = $component.find('.c-events-calendar__filters-list'),
            $children = $list.children(),
            wrapIndex = -1

            $component.addClass('c-events-calendar--wrap-filters')
        

        $children.each((i, element) => {
            let $child = $(element)
            let isHeading = $child.hasClass('c-events-calendar__filter-heading')

            // create wrap elements 
            if(isHeading) {
                wrapIndex++
                $list.append('<div class="c-events-calendar__filters-list-wrap" data-index="' + wrapIndex + '"><div class="c-events-calendar__filters-list-overflow"></div></div>')
                element.setAttribute('data-content', element.innerText.trim()) // add data so content can be targeted
            }

            // store wrap element by index
            let $wrap = $list.find('.c-events-calendar__filters-list-wrap').eq(wrapIndex)

            // move element into wrap
            if($wrap.length) {
                isHeading ? $wrap.prepend($child) : $wrap.find('.c-events-calendar__filters-list-overflow').append($child)
            }
        })     
    }
}