import { _$, _$$ } from "./shared"

class Gallery {
    constructor(element) {
        const descriptions = element.querySelectorAll('.c-grid-filter__description')
        const inputs = element.querySelectorAll('.c-grid-filter__radio-input')

        descriptions.forEach((description, index) => {
            inputs[index + 1].prepend(description)
        });
    }
}

export default Gallery