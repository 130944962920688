export const _$ = (selector) => {
    return document.querySelector(selector)
}
export const _$$ = (selector) => {
    return document.querySelectorAll(selector) || []
}
export const removeClass = (selector, className) => {
    if(selector.length == 1) {
        selector.classList.remove(className)
    } else {
        selector.forEach((el) => {
            el.classList.remove(className)
        })
    }
}

$('.js-newsletter-trigger').click(function(e) {
    e.preventDefault();
    $('body').addClass('modal-open');
    $('.js-newsletter-modal').addClass('active');
    // var iframeSource = $('.js-newsletter-modal iframe').attr('data-src');
    // $('.js-newsletter-modal iframe').attr('src', iframeSource);
});

$('.js-newsletter-modal-close').click(function(e) {
    e.preventDefault();
    $('body').removeClass('modal-open');
    $('.js-newsletter-modal').removeClass('active');
});