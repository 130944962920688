class ContentPostsFilter {
    constructor(container) {
        container.querySelectorAll('input').forEach(element => {
            element.addEventListener('change', () => {
                container.submit()
            })
        })
    }
}

export default ContentPostsFilter
