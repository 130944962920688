class GridFilter {
    constructor(container) {
        this.elements = {
            container: container,
            filter: container.querySelector('.js-filter')
        }

        if(this.elements.filter) {
            this.updateState()

            this.elements.filter.addEventListener('change', this.updateState.bind(this))
        }
    }

    updateState() {
        if (this.elements.filter.value === '*') {
            this.elements.container.classList.add('is-default-state')
        } else {
            this.elements.container.classList.remove('is-default-state')
        }

        // Trigger a custom event to notify Isotope of the state change
        this.triggerIsotopeLayoutAdjustment()
    }

    triggerIsotopeLayoutAdjustment() {
        const isotopeEvent = new CustomEvent('layoutAdjustment', { bubbles: true, cancelable: true })
        this.elements.filter.dispatchEvent(isotopeEvent)
    }
}

export default GridFilter
