export default class MediaGroupIntersectionObserver {
    constructor(element) {
        this.elements = {
            base: $(element),
            container: $(element).find('.js-media-group-items'),
            item: $(element).find('.js-media-group-item'),
        }

        this.setObserver()
    }

    setObserver() {
        this.elements.container.addClass('js-observing')

        const thresholdArray = steps => Array(steps + 1).fill(0)
        .map((_, index) => index / steps || 0)

        const handleIntersect = entries => {
            entries.forEach(entry => {
                const target = entry.target,
                    currentY = entry.boundingClientRect.y,
                    currentRatio = entry.intersectionRatio,
                    isIntersecting = entry.isIntersecting;

                if (currentY < target.previousY) {
                    if (currentRatio >= .4 && currentRatio > target.previousRatio && isIntersecting) {
                        $(target).addClass('is-visible')
                    }
                } else if (currentY > target.previousY && isIntersecting) {
                    if (currentRatio <= .95 && currentRatio < target.previousRatio) {

                        $(target).removeClass('is-visible')
                    } else {
                        $(target).addClass('is-visible')
                    }
                }

                target.previousY = currentY
                target.previousRatio = currentRatio
            })
        }

        const observer = new IntersectionObserver(handleIntersect, {
            threshold: thresholdArray(20)
        })

        this.elements.item.each((key, element) => {
            $(element).addClass('hidden');

            element.previousY = 0
            element.previousRatio = 0

            observer.observe(element)
        })
    }
};