import { _$, _$$, removeClass } from "./shared"

class MediaGroupTabbedAccordion {
    constructor() {
        this.buttons = _$$('.js-media-group-tabbed-accordion-item-trigger')
        this.items = _$$('.js-media-group-tabbed-accordion-item')
        this.bindClick()
    }

    bindClick() {
        this.buttons.forEach((el, i) => {
            $('body').trigger('revealOffcanvas')

            el.onclick = () => {
                removeClass(this.buttons, 'is-active')
                removeClass(this.items, 'is-active')

                el.classList.add('is-active')
                this.items.item(i).classList.add('is-active')

                $('body').trigger('revealOffcanvas')
            }
        })
    }
}

export default MediaGroupTabbedAccordion
